import React, { } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";



// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/bundle";
import "swiper/css/navigation";

import "./sled.css";

// import required modules
import { EffectCoverflow, Navigation, Pagination } from "swiper";

export default function Slides() {


  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        navigation={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="mySwiper mt-6"


      >
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="verre de rosé" src={process.env.PUBLIC_URL + "/images/verre.jpg"} />
          <p className="font-fontNav text-white">Rosé Saint Maur</p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="salade lyonnaise" src={process.env.PUBLIC_URL + "/images/salade.jpg"} />
          <p className="font-fontNav text-white">La Lyonnaise   <strong className="text-red-500"> 12h-14H</strong></p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="rigotte de Condrieu" src={process.env.PUBLIC_URL + "/images/rigotte.jpg"} />
          <p className="font-fontNav text-white">Produits locaux</p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="truite" src={process.env.PUBLIC_URL + "/images/truite.jpg"} />
          <p className="font-fontNav text-white">La Truite <strong className="text-red-500">12h-14H</strong></p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="cote de boeuf" src={process.env.PUBLIC_URL + "/images/cdb.jpg"} />
          <p className="font-fontNav text-white">Côte de Boeuf <strong className="text-red-500">19h-21H</strong></p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="grenouilles" src={process.env.PUBLIC_URL + "/images/grenouilles.jpg"} />
          <p className="font-fontNav text-white">Grenouilles fraîches <strong className="text-red-500">19h-21h</strong></p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="praline de Batiste" src={process.env.PUBLIC_URL + "/images/praline.jpg"} />
          <p className="font-fontNav text-white">La Praline de Batiste </p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="bouteille simone" src={process.env.PUBLIC_URL + "/images/simone.jpg"} />
          <p className="font-fontNav text-white">Vignerons d'excellence</p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="poulet ecrevisse" src={process.env.PUBLIC_URL + "/images/ecrevisse.jpg"} />
          <p className="font-fontNav text-white">Plat signature <strong className="text-red-500">12h-14H</strong></p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="poisson" src={process.env.PUBLIC_URL + "/images/poisson.jpg"} />
          <p className="font-fontNav text-white">Produits frais</p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="truffe noire" src={process.env.PUBLIC_URL + "/images/truffe.jpg"} />
          <p className="font-fontNav text-white">Truffe noire</p>
        </SwiperSlide>
        <SwiperSlide className="" >
          <img class="object-scale-down border-b border-dotted" alt="croissant" src={process.env.PUBLIC_URL + "/images/croissant.jpg"} />
          <p className="font-fontNav text-white">Croissant frais <strong className="text-red-500">08h-11H</strong></p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="croissant" src={process.env.PUBLIC_URL + "/images/pdj2.jpg"} />
          <p className="font-fontNav text-base  text-white">Petit déjeuner <strong className="text-red-500">08h-11H</strong> </p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="machine à café" src={process.env.PUBLIC_URL + "/images/cafeM.jpg"} />
          <p className="font-fontNav text-white">Machine Italienne</p>
        </SwiperSlide>
        <SwiperSlide className="">
          <img class="object-scale-down border-b border-dotted" alt="café" src={process.env.PUBLIC_URL + "/images/cafe.jpg"} />
          <p className="font-fontNav text-white">Arabica d'exception</p>
        </SwiperSlide>
      </Swiper>

    </>
  );
}
