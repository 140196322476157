import { Dialog, Transition } from '@headlessui/react'
import { ArrowSmRightIcon } from '@heroicons/react/outline'
import { Fragment, useState } from 'react'




export default function MyModal() {
    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    return (
        <>
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={openModal}
                    className="rounded-md bg-black bg-opacity-20 px-4 py-2 text-large font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                >
                    Voir les horaires réservés aux groupes
                </button>
            </div>

            <Transition appear show={isOpen} as={Fragment} >
                <Dialog as="div" className="relative z-40" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white/90 p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="mb-3 text-center font-semibold text-xl font-fontNav tracking-wider font-medium leading-6 text-gray-900"
                                    >
                                        Horaires destinés aux groupes
                                    </Dialog.Title>
                                    <div className='flex  justify-around  '>

                                        <ul className='text-lg list-disc-none font-fontNav text-red list-inside'>
                                            
                                            <li className='inline-flex items-center' >Lundi soir</li> <br/>
                                            <li className='inline-flex items-center' >Mardi soir</li><br/>
                                            <li className='inline-flex items-center' >Mercredi soir</li>
                                        </ul>
                                        <ul className='list-disc-none font-fontNav text-lg text-red list-inside'>
                                            
                                            <li className='inline-flex items-center' >Samedi midi</li><br/>
                                            <li className='inline-flex items-center' >Dimanche midi</li>
                                        </ul>
                                    </div>
                                    <div className="mt-4 flex justify-center">
                                        <button
                                            type="button"
                                            className="rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-600 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            J'ai compris !
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
