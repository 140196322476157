import '../Components/map.css'
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar'
import "aos/dist/aos.css";
import { useEffect } from 'react';
import AOS from 'aos';
import Slides from '../Components/Slides';
import MyModal from '../Components/Modal';







export default function HomePage() {

    const currentTime = new Date()
    const openHoursWTF = currentTime.getHours() >= 8 && currentTime.getHours() < 24
    const openHoursMT = currentTime.getHours() >= 8 && currentTime.getHours() < 19


    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);


    return (
        <><Navbar />
            <div className="h-full static bg-gray-900 border border-dotted border-gray-900">
                <a href='tel:04 87 38 27 71' className='fixed z-40 bottom-2 right-2  lg:hidden rounded-full border border-red-500 p-4'>
                    <svg xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M17.924 2.617a.997.997 0 00-.215-.322l-.004-.004A.997.997 0 0017 2h-4a1 1 0 100 2h1.586l-3.293 3.293a1 1 0 001.414 1.414L16 5.414V7a1 1 0 102 0V3a.997.997 0 00-.076-.383z" />
                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                    </svg></a>
                {currentTime.getDay() === 0 || currentTime.getDay() === 6 ? <strong class="z-40 lg:fixed fixed bottom-2 lg:bottom-2 lg:left-2 inline-flex items-center p-2 space-x-2  text-sm font-medium border border-red-600  rounded">
                    <span class="animate-ping  w-3 h-3 bg-red-600 rounded-full"></span>
                    <span class="font-medium text-red-600">
                        Nous sommes actuellement fermés
                    </span>
                </strong> : currentTime.getDay() < 6 && currentTime.getDay() > 2 && openHoursWTF ? <strong class="z-40 lg:fixed bottom-2 fixed lg:bottom-2 lg:left-2 inline-flex items-center p-2 space-x-2  text-sm font-medium border border-green-600  rounded">

                    <span class="animate-ping  w-3 h-3 bg-green-600 rounded-full"></span>
                    <span class="font-medium text-green-600">
                        Nous sommes actuellement ouverts
                    </span>
                </strong> : currentTime.getDay() < 3 && currentTime.getDay() >= 1 && openHoursMT ? <strong class="z-40 lg:fixed bottom-2 fixed lg:bottom-2 lg:left-2 inline-flex items-center p-2 space-x-2  text-sm font-medium border border-green-600  rounded">
                    <span class="animate-ping  w-3 h-3 bg-green-600 rounded-full"></span>
                    <span class="font-medium text-green-600">
                        Nous sommes actuellement ouverts
                    </span>
                </strong> : <strong class="z-40 lg:fixed fixed bottom-2 lg:bottom-2 lg:left-2 inline-flex items-center p-2 space-x-2  text-sm font-medium border border-red-600  rounded">
                    <span class="animate-ping  w-3 h-3 bg-red-600 rounded-full">
                    </span>
                    <span class="font-medium text-red-600">
                        Nous sommes actuellement fermés
                    </span>
                </strong>}

                <section id="menu" className="mt-16   space-x-2  justify-center md:flex-nowrap p-4">
                    <header>
                        <aside
                            class="overflow-hidden sm:grid sm:grid-cols-2 sm:items-center bg-gray-900"
                        >
                            <article class="p-5 max-w-lg mx-auto text-center  lg:text-left  lg:mx-0">
                                <h1 class="text-3xl tracking-wider text-white font-medium sm:text-4xl font-fontNav">Bienvenue au Bistrot Jaillet </h1>
                                <div className=' border-t border-dotted border-white '><p class="font-fontNav translate-y-4  font-medium first-letter:text-red-500 first-letter:text-2xl mt-6 mb-3 p-8   border-red-600 border-dotted  text-white text-xl  bg-gray-900/30">
                                    Bistrot Jaillet Père et Fils vous propose, au quotidien, une cuisine Lyonnaise savoureuse et authentique, découvrez nos plats du jour servis du Lundi au Vendredi !
                                    <ul data-aos="fade-down" className=' sm:text-lg list-disc-none font-fontNav text-white list-inside mt-3 '>
                                        <li className='text-red-500'> <p className='text-white/80 font-fontFrog first-letter:text-red-500 font-light text-xl '>Retrouvez la carte du restaurant pour le dîner du mercredi au vendredi ! ! !</p></li>
                                    </ul>
                                </p>
                                </div>
                                <a href='https://www.facebook.com/Bistrot-Jaillet-112291227936383/menu' rel="noreferrer" target="_blank" className="  translate-y-5 mb-5   bg-[url('./logoJ.png')] bg-center bg-repeat-x inline-block p-0.5 lg:ml-8  active:text-opacity-75 focus:outline-none  lg:hover:translate-x-3 lg:hover:transition duration-300 text-center" >   <span class="block px-3 py-4 md:px-6 lg:px-6 bg-gray-900 text-white text-xl font-fontNav">
                                    Voir le Menu de la Semaine
                                </span></a>
                            </article>
                            <Slides />
                        </aside>
                    </header>
                </section>
                <div className=" flex justify-center"> <div className="my-8 w-full text-center h-1 bg-[url('./logoJ.png')]  bg-center"></div></div>
                <section id='vision' className='lg:mb-48'>
                    <div class="px-4 py-16 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
                        <div class="grid grid-cols-1 lg:grid-cols-2 lg:h-screen ">
                            <div class="relative z-10 lg:py-16">
                                <div data-aos="fade-up" class="relative h-64 sm:h-80 lg:h-full border-t border-r border-red-600 border-dotted">
                                    <img
                                        class="absolute inset-0 object-cover object-top sepia w-full h-full"
                                        src={process.env.PUBLIC_URL + '/images/perefils.jpg'}
                                        alt=" Batiste et David"
                                    />
                                </div>
                            </div>

                            <div class="relative flex items-center bg-red-600 opacity-60  relative border-r border-red-600 border-dotted ">
                                <span
                                    class="hidden lg:inset-y-0 lg:absolute  lg:w-16 lg:bg-red-600 lg:block lg:-left-16"
                                ></span>

                                <div data-aos="fade-down" class="p-8  sm:p-16 lg:p-24 ">
                                    <h2 class=" font-fontNav text-4xl lg:text-5xl p-6 md:text-5xl backdrop-grayscale-0 bg-gray-700/40  tracking-widest text-white ">
                                        Didier et Batiste du Bistrot Jaillet
                                    </h2>

                                    <p class="font-fontNav text-xl mt-6 mb-6  p-2 text-white font-light">
                                        Dans la famille Jaillet, le père et le fils se sont réunis pour monter leur bistrot de quartier au coeur de Lyon 6ème, à l’ambiance chaleureuse et à la cuisine traditionnelle.
                                        Ils vous proposent, une cuisine de qualité avec des produits uniquement issus de notre région.
                                    </p>

                                    <p className='font-fontNav text-xl p-2 text-white font-light'>
                                        Didier et Batiste offrent également des possibilités <strong className=' underline-offset-4 underline decoration-2 decoration-gray-800/30'>de privatisation</strong>  (sur réservation) pour <strong className=' underline-offset-4 underline decoration-2 decoration-gray-800/30'>les groupes</strong>  aux horaires suivantes : </p>
                                    <div className='flex justify-center p-2  '>
                                        <li className=' font-fontNav text-xl text-white list-none'><MyModal /></li>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div id='horaire' className=" flex justify-center">  </div>
                <section className=" flex flex-wrap border-t border-dotted md:mt-5  space-x-2 mb-8  justify-center md:flex-nowrap p-5">
                    <article className="w-80 h-80 border-b border-r  border-dotted border-red-600   md:w-1/2">
                        <h2 className='font-fontNav text-2xl text-white text-center tracking-widest  p-3 mb-4'>Horaires</h2>
                        <div className='flex justify-center border-t border-dotted border-red-500  '>
                            <ul className="tracking-widest border-l  text-white border-dotted border-red-600 mr-2 font-semibold lg:p-8 p-4 ">
                                <li className='font-fontNav p-2'>Lundi </li>
                                <li className='font-fontNav p-2'>Mardi </li>
                                <li className='font-fontNav p-2'>Mercredi </li>
                                <li className='font-fontNav p-2'>Jeudi </li>
                                <li className='font-fontNav p-2'>Vendredi </li>
                            </ul>
                            <ul className=" tracking-wider border-l  text-white border-dotted border-red-600 lg:p-8 p-4">
                                <li className='font-fontNav p-2'>08H--19h </li>
                                <li className='font-fontNav p-2'>08H--19h </li>
                                <li className='font-fontNav p-2'>08H--00h </li>
                                <li className='font-fontNav p-2'> <span className='font-semibold'>08H--00h</span> </li>
                                <li className='font-fontNav p-2'> <span className='font-semibold'>08H--00h</span> </li>
                            </ul>
                        </div>

                        <a href="https://www.google.com/maps/dir//Bistrot+Jaillet/@45.763992,4.7757572,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47f4ebaa6b92b13d:0xe4b6626660bfdacc!2m2!1d4.8458136!2d45.7640149" rel="noreferrer" target="_blank" class="lg:mt-4 ml-20 mt-8 font-fontNav text-2xl font-bold relative inline-flex items-center px-8 py-3 overflow-hidden text-red-600 border border-current rounded group active:text-red-600 focus:outline-none  " >
                            <span class="absolute right-0 transition-transform translate-x-full group-hover:-translate-x-4">
                                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                                </svg>
                            </span>
                            <span class="text-base font-medium transition-all group-hover:mr-4">
                                Voir l'itinéraire
                            </span>
                        </a>
                    </article>
                    <article data-aos="fade-up" title="Carte représentant l'emplacement du Bistrot"
                        className="shadow-lg mt-20  saturate-150  w-80 h-80  
                       md:w-1/3 bg-[url('https://maps.googleapis.com/maps/api/staticmap?scale=1&size=1600x900&style=feature:poi.business|visibility:off&style=feature:water|visibility:simplified&style=feature:road|element:labels.icon|visibility:off&style=feature:road.highway|element:labels|saturation:-90|lightness:25&format=jpg&language=fr&region=FR&markers=color:0xdb5e5e|45.7640134,4.8457970&zoom=16&client=google-presto&signature=1TMZ4_0As5rOd016_j1dlCI0eA0')] bg-center">
                    </article>
                </section>
                <div className=" w-full h-1  bg-[url('./logoJ.png')]  bg-center"></div>
            </div>
            <Footer />
        </>
    )
}