import { BrowserRouter, Route, Routes } from 'react-router-dom';

import HomePage from './Pages/HomePage';
import "swiper/css/bundle";
import "./Components/sled.css"
import MentionsLegales from './Pages/Mentions-legales';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/mentionslegales" element={<MentionsLegales />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
