import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'


const navigation = [
  { name: 'Menu', href: '#menu', current: false },
  { name: 'Notre vision', href: '#vision', current: false },
  { name: 'Informations', href: '#horaire', current: false },
  { name: 'Reserver', href: '#reserver', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  return (
    <Disclosure as="nav" className="bg-gray-900/90 fixed w-full z-40  shadow-lg">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white ">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6 mt-3" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="block h-16 mt-4 w-auto lg:hidden"
                    src={process.env.PUBLIC_URL + '/images/logoNav.JPG'}
                    alt="Bistrot Jaillet logo"
                  />
                  <img
                    className="hidden h-16 mt-3 w-auto lg:block"
                    src={process.env.PUBLIC_URL + '/images/logoNav.JPG'}
                    alt="Bistrot Jaillet logo"
                  />
                </div>
                <div className="hidden  sm:block ">
                  <div className="flex mx-6 md:mx-2 space-x-12 md:space-x-8 sm:space-x-3  ">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : ' text-red-500 translate-y-5 font-fontNav lg:text-xl md:text-base sm:text-base font-medium hover:text-white hover:underline underline-offset-8 decoration-1 hover:duration-300',
                          'px-3 py-2 text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>

              </div>

            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white font-fontNav' : 'font-fontNav text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
